import { ref, watch, computed } from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line no-unused-vars
import { title } from '@core/utils/filter'

// Notification
import { useToast } from 'vue-toastification/composition'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default function useUsersList() {
  // Use toast
  const toast = useToast()

  const refUserListTable = ref(null)

  // Table Handlers
  const tableColumns = [
    { key: 'tanggal', label: 'Date', sortable: true },
    { key: 'operator', sortable: true },
    { key: 'shift', sortable: true },
    { key: 'batchId', sortable: true },
    { key: 'IntCnt', sortable: true },
    { key: 'Mesin', sortable: true },
    { key: 'Produksi', sortable: true },
    { key: 'Mold', sortable: true },
    { key: 'PCH', sortable: true },
    { key: 'PCL', sortable: true },
    { key: 'PL1', sortable: true },
    { key: 'PL2', sortable: true },
    { key: 'PL3', sortable: true },
    { key: 'PL4', sortable: true },
    { key: 'BP', sortable: true },
    { key: 'PH1', sortable: true },
    { key: 'PH2', sortable: true },
    { key: 'PH3', sortable: true },
    { key: 'PH4', sortable: true },
    { key: 'V1', sortable: true },
    { key: 'V2', sortable: true },
    { key: 'V3', sortable: true },
    { key: 'V4', sortable: true },
    { key: 'V5', sortable: true },
    { key: 'VS', sortable: true },
    { key: 'VOS1', sortable: true },
    { key: 'VO1', sortable: true },
    { key: 'VO2', sortable: true },
    { key: 'VOS2', sortable: true },
    { key: 'VC1', sortable: true },
    { key: 'VC2', sortable: true },
    { key: 'VC3', sortable: true },
    { key: 'VCS', sortable: true },
    { key: 'VE', sortable: true },
    { key: 'VR', sortable: true },
    { key: 'LS4', sortable: true },
    { key: 'LS4D', sortable: true },
    { key: 'LS4C', sortable: true },
    { key: 'LS4B', sortable: true },
    { key: 'LS4A', sortable: true },
    { key: 'LS5', sortable: true },
    { key: 'LS5A', sortable: true },
    { key: 'LS10', sortable: true },
    { key: 'RPM', sortable: true },
    { key: 'TR1', sortable: true },
    { key: 'TR2', sortable: true },
    { key: 'TR4', sortable: true },
    { key: 'CHRG', sortable: true },
    { key: 'TH1', sortable: true },
    { key: 'TH2', sortable: true },
    { key: 'TH3', sortable: true },
    { key: 'TH4', sortable: true },
    { key: 'TT', sortable: true },
    { key: 'CT', sortable: true },
    { key: 'HEN', sortable: true },
    { key: 'HN', sortable: true },
    { key: 'H1', sortable: true },
    { key: 'H2', sortable: true },
    { key: 'H3', sortable: true },
    { key: 'H4', sortable: true },
    { key: 'H5', sortable: true },
    { key: 'H6', sortable: true },
    { key: 'OIL', sortable: true },
    { key: 'LS3A', sortable: true },
    { key: 'LS3D', sortable: true },
    { key: 'LS3E', sortable: true },
    { key: 'LS3B', sortable: true },
    { key: 'LS3', sortable: true },
    { key: 'LS2D', sortable: true },
    { key: 'LS2E', sortable: true },
    { key: 'LS2A', sortable: true },
    { key: 'LS2', sortable: true },
    { key: 'LS31', sortable: true },
    { key: 'LS32', sortable: true },
    { key: 'Shoot', sortable: true },
    { key: 'MC', sortable: true },
    { key: 'MO', sortable: true },
    // { key: 'actions' },
  ]
  const perPage = ref(50)
  const totalData = ref(0)
  const currentPage = ref(1)
  const perPageOptions = [5, 10, 25, 50, 100]
  const searchQuery = ref('')
  const sortBy = ref('Tanggal')
  const isSortDirDesc = ref(true)
  const roleFilter = ref(null)
  const toFilter = ref(null)
  const fromFilter = ref(null)
  const planFilter = ref(null)
  const statusFilter = ref(null)

  const dataMeta = computed(() => {
    const localItemsCount = refUserListTable.value ? refUserListTable.value.localItems.length : 0
    return {
      from: perPage.value * (currentPage.value - 1) + (localItemsCount ? 1 : 0),
      to: perPage.value * (currentPage.value - 1) + localItemsCount,
      of: totalData.value,
    }
  })

  const refetchData = () => {
    refUserListTable.value.refresh()
  }

  watch([currentPage, perPage, searchQuery, roleFilter, fromFilter, toFilter, planFilter, statusFilter], () => {
    refetchData()
  })

  const fetchData = (ctx, callback) => {
    store
      .dispatch('app-user/fetchData', {
        q: searchQuery.value,
        perPage: perPage.value,
        page: currentPage.value,
        sortBy: sortBy.value,
        sortDesc: isSortDirDesc.value,
        machine: roleFilter.value,
        tgl_from: fromFilter.value,
        tgl_to: toFilter.value,
        shift: planFilter.value,
        mold: statusFilter.value,
      })
      .then(response => {
        // const { users, total } = response.data

        callback(response.data.data)
        totalData.value = response.data.count
      })
      .catch(() => {
        toast({
          component: ToastificationContent,
          props: {
            title: 'Error fetching users list',
            icon: 'AlertTriangleIcon',
            variant: 'danger',
          },
        })
      })
  }

  // *===============================================---*
  // *--------- UI ---------------------------------------*
  // *===============================================---*

  const resolveUserRoleVariant = role => {
    if (role === 'subscriber') return 'primary'
    if (role === 'author') return 'warning'
    if (role === 'maintainer') return 'success'
    if (role === 'editor') return 'info'
    if (role === 'admin') return 'danger'
    return 'primary'
  }

  const resolveUserRoleIcon = role => {
    if (role === 'subscriber') return 'UserIcon'
    if (role === 'author') return 'SettingsIcon'
    if (role === 'maintainer') return 'DatabaseIcon'
    if (role === 'editor') return 'Edit2Icon'
    if (role === 'admin') return 'ServerIcon'
    return 'UserIcon'
  }

  const resolveUserStatusVariant = status => {
    if (status === 'pending') return 'warning'
    if (status === 'active') return 'success'
    if (status === 'inactive') return 'secondary'
    return 'primary'
  }

  // taskLocal
  // ------------------------------------------------
  const taskLocal = ref([])
  const resetTaskLocal = () => {
    taskLocal.value = []
  }
  // watch(props.task, () => {
  //   resetTaskLocal()
  // })

  return {
    taskLocal,
    resetTaskLocal,

    fetchData,
    tableColumns,
    perPage,
    currentPage,
    totalData,
    dataMeta,
    perPageOptions,
    searchQuery,
    sortBy,
    isSortDirDesc,
    refUserListTable,

    resolveUserRoleVariant,
    resolveUserRoleIcon,
    resolveUserStatusVariant,
    refetchData,

    // Extra Filters
    roleFilter,
    fromFilter,
    toFilter,
    planFilter,
    statusFilter,
  }
}
